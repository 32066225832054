
// TO USE: wrap this component with an class have position relative and the same parrent with the button to show this

import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuOptionDropdown",
  props: {
    isMenu: { type: Boolean, default: true },
  },
});
