import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05b0ffde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner content" }
const _hoisted_2 = { id: "copyright" }
const _hoisted_3 = { class: "link-area" }
const _hoisted_4 = { class: "row-child" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  href: "https://services.digitalsign.jp/terms",
  target: "_blank",
  class: "first"
}
const _hoisted_7 = {
  href: "https://services.digitalsign.jp/laws",
  target: "_blank"
}
const _hoisted_8 = {
  href: "https://services.digitalsign.jp/privacy",
  target: "_blank"
}
const _hoisted_9 = { class: "row second" }
const _hoisted_10 = {
  href: "https://services.digitalsign.jp/corporate",
  target: "_blank"
}
const _hoisted_11 = {
  href: "https://help.digitalsign.jp/",
  target: "_blank"
}
const _hoisted_12 = {
  href: "https://help.digitalsign.jp/hc/ja/requests/new",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass({
      'white-footer': _ctx.whiteFooter,
      'transparent-footer': _ctx.transparentFooter,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("footer.copyright")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t("footer.termsOfService")), 1),
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("footer.notation")), 1),
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t("footer.privacy")), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t("footer.operatingCompany")), 1),
            _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t("footer.faq")), 1),
            _createElementVNode("a", _hoisted_12, _toDisplayString(_ctx.$t("footer.contact")), 1)
          ])
        ])
      ])
    ])
  ], 2))
}