
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  props: {
    isAfterLogin: { type: Boolean, default: false, required: false },
    whiteFooter: { type: Boolean, default: false, required: false },
    transparentFooter: { type: Boolean, default: false, required: false },
  },
});
/**
 * @vuese
 * @group Atomic/Organisms
 * footter
 */
