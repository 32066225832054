
import { defineComponent, reactive, toRefs } from "vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";

import { useRouter } from "vue-router";
import storage from "@/lib/storage/storageHelper";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "LogoHeader",
  props: {
    isLoggedIn: { type: Boolean, default: false, required: false },
    user: { type: Object },
  },
  components: {
    MenuOptionDropdown,
    TextButton,
    FlatButton,
    AvatarCircle,
  },
  setup(props) {
    const state = reactive({
      isShowMyAccount: false,
    });

    const router = useRouter();
    const localStorage = storage.getLocalStorage();

    const logout = () => {
      localStorage.clear();
      router.push({ name: "Login" });
    };

    const navigationToAccount = () => {
      state.isShowMyAccount = !state.isShowMyAccount;
      router.push("/account");
    };

    return {
      getFilePathFromUrl,
      ...toRefs(state),
      logout,
      navigationToAccount,
    };
  },
  mounted() {
    window.addEventListener("click", this.dismiss);
  },

  unmounted() {
    window.removeEventListener("click", this.dismiss);
  },
  methods: {
    dismiss(event: Event) {
      if (
        this.isShowMyAccount &&
        !(this.$el as HTMLElement).contains(event.target as Element)
      ) {
        this.isShowMyAccount = false;
      }
    },
  },
});
/**
 * @vuese
 * @group Atomic/Organisms
 * footter
 */
