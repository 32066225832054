import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aa29a13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "register-header" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "header-logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "separator"
}
const _hoisted_6 = { class: "username" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "user-detail" }
const _hoisted_9 = {
  key: 0,
  class: "username mt-2"
}
const _hoisted_10 = { class: "mail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_text_button = _resolveComponent("text-button")!
  const _component_menu_option_dropdown = _resolveComponent("menu-option-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/dashboard" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: require('@/assets/logo/digitalsign-logo-black.svg')
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ]),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "user",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowMyAccount = !_ctx.isShowMyAccount))
            }, [
              _createVNode(_component_AvatarCircle, {
                class: "avatar",
                avatar: 
              _ctx.user?.profilePicture
                ? _ctx.getFilePathFromUrl(_ctx.user.profilePicture)
                : require('icons/default-avatar.svg')
            ,
                onClick: _ctx.onClickAvatar
              }, null, 8, ["avatar", "onClick"]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.user?.lastName && _ctx.user?.lastName)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.user?.lastName + " " + _ctx.user?.firstName), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_menu_option_dropdown, { class: "user-info-popup" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AvatarCircle, {
                class: "avatar",
                avatar: 
                _ctx.user?.profilePicture
                  ? _ctx.getFilePathFromUrl(_ctx.user.profilePicture)
                  : require('icons/default-avatar.svg')
              
              }, null, 8, ["avatar"]),
              (_ctx.user?.lastName && _ctx.user?.lastName)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.user?.lastName + " " + _ctx.user?.firstName), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.user?.email), 1),
              _createVNode(_component_flat_button, {
                onOnClick: _ctx.navigationToAccount,
                text: _ctx.$t('header.myAccount'),
                class: "mt-2 w-full"
              }, null, 8, ["onOnClick", "text"]),
              _createVNode(_component_text_button, {
                text: _ctx.$t('header.logout'),
                class: "logout-btn",
                onOnClick: _ctx.logout
              }, null, 8, ["text", "onOnClick"])
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.isShowMyAccount]
        ])
      ])
    ])
  ]))
}